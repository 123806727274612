import React from "react"
import {Container} from "react-bootstrap"
import {Picture} from "./section/picture/Picture"
import {Profile} from "./section/profile/Profile"
import {Education} from "./section/education/Education"
import {Experience} from "./section/experience/Experience"
import {Skills} from "./section/skills/Skills"
import {Tools} from "./section/tools/Tools"
import {Languages} from "./section/languages/Languages"
import {Row, Col} from "react-bootstrap"
import {Projects} from "./section/projects/Projects"
import {Title} from "./components/title/Title"
import {Contact} from "./section/contact/Contact"

function App() {


    return (
        <>
            <Container fluid className="inner header">
                <div className="black">
                    <Container fluid>
                        <Picture/>
                    </Container>
                </div>
            </Container>
            <Container fluid className="main">
                <Row>
                    <Col className="content left justify-content-md-center">
                        <Title title="Profile">
                            <Profile/>
                        </Title>
                        <Title title="Education & Certificates">
                            <Education/>
                        </Title>
                        <Title title="Skills">
                            <Skills/>
                        </Title>
                        <Title title="Tools">
                            <Tools/>
                        </Title>
                        <Title title="Languages">
                            <Languages/>
                        </Title>
                        <div className="side-projects">
                            <Title title="Side projects">
                                <Container fluid>
                                    <Projects/>
                                </Container>
                            </Title>
                        </div>
                    </Col>
                    <Col className="content right justify-content-md-center">
                        <Title title="Work Experience" className="move-left-15">
                            <Experience/>
                        </Title>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="inner footer">
                <div className="contact">
                    <Container>
                        <Row>
                            <Col>
                                <Title title="Contact" className={"contact-title"}>
                                    Drop me an email if you want to contact
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col>
                                <Contact/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </>
    )
}

export default App
