import React, {FC, useState, useRef} from 'react';
import './ProjectName.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { normalizeClass } from '../../utils';

export type ProjectNameType = { name:string, description: string | React.ReactNode } | string

export type ProjectNameProps = { project:ProjectNameType }

export const ProjectName:FC<ProjectNameProps> = ({ project }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    
    if(typeof project === "string") {
        return <span className="project-name">{ project }</span>
    }
    
    const overlayId:string = normalizeClass(`overlay-${project.name}`)

    return (
        <>
        <OverlayTrigger overlay={(props) => (
            <Popover id={overlayId} {...props}>
                <Popover.Content>
                    { project.description }
                </Popover.Content>
            </Popover>
            )}>
            <span className="project-name clickable" ref={target} onClick={() => setShow(!show)}>{ project.name }</span>
        </OverlayTrigger>
        </>
        )
}
