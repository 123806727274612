import React, {FC} from 'react'
import {Col, Row} from 'react-bootstrap'
import './Languages.scss'
import {Experience} from "./Experience";
import take from 'lodash/fp/take';
import drop from 'lodash/fp/drop';

type LanguageItem = {
  name:string,
  level:Experience
}


const languages:LanguageItem[] = [
  {
    name: "English",
    level: Experience.ADVANCED
  },
  {
    name: "Polish",
    level: Experience.NATIVE
  },
  {
    name: "German",
    level: Experience.BASIC
  },
  {
    name: "Spanish",
    level: Experience.BASIC
  },
  {
    name: "Japanese",
    level: Experience.LEARNING
  }
]

export const Languages: FC<{}> = () => {
  return (
    <Row>
      <Col>
        <ul id="languages">
          { take(3, languages).map((language:LanguageItem) => <ol key={language.name}><span>{language.name}</span> <strong>{language.level}</strong></ol>) }
        </ul>
      </Col>
      <Col>
        <ul id="languages">
          { drop(3, languages).map((language:LanguageItem) => <ol key={language.name}><span>{language.name}</span> <strong>{language.level}</strong></ol>) }
        </ul>
      </Col>
    </Row>
  )
}