import React, {ComponentProps, FC} from 'react'
import {Row, Col} from 'react-bootstrap'
import './Project.scss'
import {Skill, SkillsList} from "../skill";
import last from "lodash/last";
import {ProjectNameType, ProjectName} from "./ProjectName";

export type ProjectProps = {
    companyName: string,
    place?: string,
    time: string,
    title?: string,
    technologies: SkillsList[],
    projects?: ProjectNameType[],
    description?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Project: FC<ProjectProps> = ({companyName, place, time, title, technologies, projects, description, ...rest}) => {
    return (
        <Row className="project" {...rest}>
            <Col xs lg={3} className="left">
                <Row><h4 className="company-name">{companyName}</h4></Row>
                {place && <Row><span className="place">{place}</span></Row>}
                <Row><span className="time">{time}</span></Row>
            </Col>
            <Col xs className="right">
                {title &&
                    <Row><h4 className="title">{title}</h4></Row>}
                <Row>
                    <ul>
                        {description && <li><span className="list-title">Description:</span> {description} </li>}
                        <li className="technology"><span className="list-title">Technologies: </span>
                            {technologies.map(skill => (
                                <Skill skill={skill} separator={", "} isLast={last(technologies) === skill}/>))}
                        </li>
                        {projects &&
                            <li className="projects"><span className="list-title">Projects:</span> {projects.map<React.ReactNode>(value =>
                                <ProjectName project={value}/>).reduce((prev, curr) => [prev, ', ', curr])}</li>}
                    </ul>
                </Row>
            </Col>
        </Row>
    )
}