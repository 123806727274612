import React, { FC } from "react"
import { Row } from "react-bootstrap"
import { Skill, SkillsList } from "../../components/skill";
import last from "lodash/last";


const proficient: SkillsList[][] = [
    [SkillsList.SCALA, SkillsList.SCALA3, SkillsList.TYPESCRIPT, SkillsList.JAVASCRIPT, SkillsList.PYTHON, SkillsList.PHP, SkillsList.JAVA],
    [SkillsList.KAFKA, SkillsList.ELASTIC, SkillsList.REDIS, SkillsList.PSQL, SkillsList.MYSQL, SkillsList.FLINK],
    [SkillsList.DOCKER, SkillsList.K8S, SkillsList.PROTOBUF, SkillsList.AWS],
    [SkillsList.REACT, SkillsList.ANGULAR, SkillsList.SCALA_JS, SkillsList.NODEJS],
    [SkillsList.AKKA, SkillsList.CATS_EFFECT, SkillsList.ZIO],
]

const faimilarWith: SkillsList[][] = [
    [SkillsList.PLAY_FRAMEWORK, SkillsList.KOAJS, SkillsList.OPENSHIFT, SkillsList.BAZEL],
    [SkillsList.MARIADB, SkillsList.RABBITMQ, SkillsList.SELENIUM, SkillsList.GO]
]


export const Skills: FC<{}> = () => {
    return (
        <>
            <Row className="move-right-5"><strong>Proficient in</strong></Row>
            <Row className="move-right-5">
                <ul>
                    {
                        proficient.map(array => {
                            return (
                                <li key={array[0]}>{
                                    array.map(skill => (
                                        <Skill skill={skill} separator={", "} isLast={last(array) === skill}/>))
                                }</li>
                            )
                        })
                    }
                </ul>
            </Row>
            <Row className="move-right-5"><strong>Familiar with</strong></Row>
            <Row className="move-right-5">
                <ul>
                    {
                        faimilarWith.map(array => {
                            return (<li key={array[0]}>{array.map(skill => (
                                <Skill skill={skill} separator={", "} isLast={last(array) === skill}/>))}</li>)
                        })
                    }
                </ul>
            </Row>
            <Row className="move-right-5"><strong>Learning</strong></Row>
            <Row className="move-right-5">
                <ul>
                    <li className="skill-like">Rust,</li>
                    <li className="skill-like">Kotlin,</li>
                    <li className="skill-like">Svelte</li>
                </ul>
            </Row>
        </>
    )
}