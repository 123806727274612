import React, {FC} from 'react'
import {Row, Col} from 'react-bootstrap'
import './Picture.scss'
import me from '../../img/me-small.jpeg'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilePdf, faMapMarkerAlt, faPhone} from '@fortawesome/free-solid-svg-icons'
import {faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'

export const Picture: FC<{}> = () => {
    return (
        <>
            <Row id="pictures">
                <Col xl={2} lg={2} md={3} xs id="picture-col">
                    <Row>
                        <img alt="Me" src={me} className="rounded" id="me"/>
                    </Row>
                </Col>
                <Col xl={7} lg={7} md={4} xs id="names">
                    <Row>
                        <span id="name">Maciej Romański&nbsp;</span>
                    </Row>
                    <Row>
                        <ul id="icons" className="no-print">
                            <li>
                                <a className="icon-link" href={process.env.PUBLIC_URL + '/cv.pdf'} target="_blank"
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </a>
                            </li>
                            <li>
                                <a className="icon-link" href="https://www.linkedin.com/in/fsloki" target="_blank"
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin}/>
                                </a>
                            </li>
                            <li>
                                <a className="icon-link" href="https://github.com/plmuninn" target="_blank"
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faGithub}/>
                                </a>
                            </li>
                        </ul>
                        <ul id="icons" className="no-screen">
                            <li>
                                <FontAwesomeIcon className="icon" icon={faLinkedin}/> <span
                                className="icon-link">https://www.linkedin.com/in/fsloki</span>
                            </li>
                            <li>
                                <FontAwesomeIcon className="icon" icon={faGithub}/> <span
                                className="icon-link">https://github.com/plmuninn</span>
                            </li>
                        </ul>
                    </Row>
                </Col>
                <Col xl={3} lg={3} md={4} xs id="location-box">
                    <Row className="justify-content-end">
                        <Col xs={3}>
                            <Row id="map-marker-container">
                                <FontAwesomeIcon icon={faMapMarkerAlt} id="map-marker"/>
                            </Row>
                        </Col>
                        <Col xs={9} id="place-container">
                            Warsaw,&nbsp;Mazowieckie <br/>
                            Poland
                        </Col>
                    </Row>
                    <Row className="justify-content-end no-screen">
                        <Col xs={3}>
                            <Row id="map-marker-container">
                                <FontAwesomeIcon icon={faPhone} id="map-marker"/>
                            </Row>
                        </Col>
                        <Col xs={9} id="contact-info-container">
                            (+48) 788754396 <br/>
                            maciej.romanski@o2.pl <br />
                            http://muninn.pl
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}