import React, { FC } from "react";
import { SkillsList } from "./skillsList"
import classnames from "classnames";

import { atom, useAtom } from "jotai"
import { normalizeClass } from "../../utils";

const selectedSkill = atom<SkillsList | {}>({})

type SkillProps = {
    skill: SkillsList | string
    separator?: string
    isLast?: boolean
    children?: React.ReactNode
}

export const Skill: FC<SkillProps> = ({skill, separator, isLast, children}) => {
    const normalisedClass = normalizeClass(skill)
    const [selected, setSelected] = useAtom(selectedSkill)

    return (
        <>
        <span
            className={classnames(["skill", `skill-${normalisedClass}`, {hover: selected !== {} && selected === skill}])}
            onMouseOver={() => setSelected(skill)}
            onMouseLeave={() => setSelected({})}
        >{skill}</span>
            <span className={`skill-${normalisedClass}`}>{!!separator && !isLast && separator}</span>{children}
        </>
    );
}