import React, { FC } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Education.scss'

interface EducationItem {
  time: string,
  name: string,
  school?: string
}

const educations: EducationItem[] = [
  {
    time: 'Nov 2013',
    name: 'Functional Programming Principles in Scala',
    school: 'Coursera'
  },
  {
    time: '2010-2013',
    name: 'Bachelor\'s degree',
    school: 'Polish-Japanese Institute of Information Technology Gdańsk'
  }
]

export const Education: FC<{}> = () => {
  return (
    <Row id="education">
      <Col>
        { educations.map(education => <>
          <Row key={education.name} className="education-item">
            <Col sm={3} className="time">
              {education.time}
            </Col>
            <Col className="name">
              <span>{education.name}</span>
            </Col>
          </Row>
          { education.school && <Row key={education.school}><Col className="school">{ education.school }</Col></Row> }
        </>) }
      </Col>
    </Row>
  )
}