import React, { FC, useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { Form, Button, Spinner } from 'react-bootstrap'
import './Contact.scss'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import * as emailjs from 'emailjs-com'

interface Email {
  from?: string,
  message?: string
}

const validationSchema = Yup.object().shape<Email>({
  from: Yup.string().email('Please provide a valid email.').required('Required.'),
  message: Yup.string().required('Required.')
})

export const Contact: FC<{}> = () => {
  const [errorSendingEmail, setErrorSendingEmail] = useState<boolean>(false)
  const [emailSend, setEmailSend] = useState<boolean>(false)

  const formik = useFormik<Email>({
    initialValues: {} as Email,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await emailjs.send('amazon_ses', 'template_rWY9UIGt', {
          from_name: values.from,
          reply_to: values.from,
          message_html: values.message
        })
        setEmailSend(true)
      } catch (e) {
        console.error(e)
        setErrorSendingEmail(true)
      }
    }
  })

  return (
    <Row id="contact-form">
      <Col>
        <Alert variant="success" show={ emailSend } onClose={ () => setEmailSend(false) } dismissible>
          <Alert.Heading>Thanks for sending email! I will replay soon.</Alert.Heading>
        </Alert>
        <Alert variant="danger" show={ errorSendingEmail } onClose={ () => setErrorSendingEmail(false) } dismissible>
          <Alert.Heading>Something went wrong. Please, try again later.</Alert.Heading>
        </Alert>
        <Form onSubmit={ formik.handleSubmit } noValidate>
          <Form.Group controlId="validationFormik01">
            <Form.Label srOnly>Your email address</Form.Label>
            <Form.Control type="email"
                          name="from"
                          placeholder="Your email address"
                          onChange={ formik.handleChange }
                          isInvalid={ !!formik.errors.from }
                          disabled={ formik.isSubmitting }
            />
            <Form.Control.Feedback type="invalid">
              { formik.errors.from }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="validationFormik02">
            <Form.Label srOnly>Message</Form.Label>
            <Form.Control as="textarea"
                          placeholder="Message"
                          name="message"
                          onChange={ formik.handleChange }
                          isInvalid={ !!formik.errors.message }
                          disabled={ formik.isSubmitting }
            />
            <Form.Control.Feedback type="invalid">
              { formik.errors.message }
            </Form.Control.Feedback>
          </Form.Group>

          <Button id="submit" variant="light" type="submit" disabled={ formik.isSubmitting }>
            { formik.isSubmitting ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : 'Send' }
          </Button>
        </Form>
      </Col>
    </Row>
  )
}