import React, { FC } from "react"
import { Project, ProjectProps } from "../../components/project/Project"
import { SkillsList } from "../../components/skill";

const projects: ProjectProps[] = [
    {
        companyName: "Clothy",
        time: "Jul 2013 – Jun 2016",
        technologies: [SkillsList.SCALA, SkillsList.PLAY_FRAMEWORK, SkillsList.REDIS, SkillsList.ELASTIC, SkillsList.SLICK, SkillsList.AKKA, SkillsList.TYPESCRIPT, SkillsList.ANGULARJS],
        description: "Instagram like application for fashion bloggers"
    },
    {
        companyName: "CF",
        time: "Jan 2020 – Present",
        technologies: [SkillsList.SCALA, SkillsList.CATS_EFFECT, SkillsList.PSQL, SkillsList.REDIS, SkillsList.ELASTIC, SkillsList.PYTHON],
        description: "Secret project for coffee lovers",
    }
]

export const Projects: FC<{}> = () => {
    return <>
        {projects.map(project => <Project {...project} key={project.companyName} className="side-project"/>)}
    </>;
}