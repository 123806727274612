import React, {FC} from 'react'
import {Skill, SkillsList} from '../../components/skill'

export const Tools: FC<{}> = () => {
    return (
        <ul>
            <li><span className="skill-like">Intellij Idea,</span> <span className="skill-like">Docker,</span> <span className="skill-like">AWS,</span> <span className="skill-like">MacOS</span></li>
            <li><Skill skill={SkillsList.K8S}/>, <span className="skill-like">Linux</span></li>
        </ul>
    )
}