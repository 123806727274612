import React, { FC, HTMLAttributes } from 'react'
import { Row, Col } from 'react-bootstrap'
import './Title.scss'


export interface TitleProps extends HTMLAttributes<any>{
  title: string
}

export const Title: FC<TitleProps> = ({title, children, className, ...rest}) =>
  <Row className="section">
    <Col xs lg={11}>
      <h1 className={ ('title' + ' ' + (className || '')).trim() } {...rest}>{ title.toUpperCase() }</h1>
      { children }
    </Col>
  </Row>