import React, {FC} from 'react'

export const Profile: FC<{}> = () => {
    return (
        <>
            <span className="profile no-print">
              Programming is my life passion and I have more than 12 years of experience designing and creating various types of software - from small
              applications to big complex systems handling big traffic. I have very solid experience in functional programming
              as well as in object oriented programming - where I prefer to mix both paradigms always trying to find the simplest and most efficient way of
              creating applications. I specialise in designing backends, tooling and systems, but
              I also have a lot of experience with creating various types of rich UIs and frontends.
            </span>
            <span className="profile no-screen">
                I love programming, designing systems and mathematical riddles. I value stability and maintainability above
                anything else in my work. I always try to “make it better” and “feel nicer”
            </span>
        </>
    )
}