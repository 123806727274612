import React, { FC } from "react"
import { Project, ProjectProps } from "../../components/project/Project"
import { SkillsList } from "../../components/skill";


const projects: ProjectProps[] = [
    {
        companyName: "zooz",
        place: "Remote",
        time: "Sep 2021 - Present",
        title: "Scala Developer",
        technologies: [SkillsList.SCALA, SkillsList.SCALA3, SkillsList.SCALA_JS, SkillsList.ZIO, SkillsList.CATS_EFFECT, SkillsList.DOCKER, SkillsList.K8S, SkillsList.PSQL, SkillsList.KAFKA, SkillsList.FLINK, SkillsList.TYPESCRIPT, SkillsList.AWS],
        projects: [
            { name: "control-center", description: "UI that is front door for data mesh subsystems" },
            { name: "ververica-deployment-cli", description: "Project for deploying Flink applications to kubernetes using CI\\CD"},
            { name: "sql-engine", description: "Engine for using SQL and configuration to automate generating Flink jobs that will consume and modify data on data mesh"},
            "masking-service",
            { name: "data-exporter", description: "Agent like application for exporting data from various databases"}
        ]
    },
    {
        companyName: "White Hat Gaming",
        place: "Remote",
        time: "Sep 2020 - Aug 2021",
        title: "Senior Scala Developer",
        technologies: [SkillsList.SCALA, SkillsList.CATS_EFFECT, SkillsList.AKKA, SkillsList.DOCKER, SkillsList.K8S, SkillsList.PSQL, SkillsList.KAFKA, SkillsList.AWS],
        projects: ["platform", {name: "sendto-segment", description:"Application that transformed system events to very detaild metrics"}]
    },
    {
        companyName: "Sony Electronics",
        place: "Gdańsk, Remote",
        time: "Dec 2018 - May 2020",
        title: "InfoSec Automation Scala Developer",
        technologies: [SkillsList.SCALA, SkillsList.CATS_EFFECT, SkillsList.MONIX, SkillsList.K8S, SkillsList.PSQL, SkillsList.REDIS, SkillsList.GRAPHQL, SkillsList.KAFKA, SkillsList.AWS],
        projects: ["ONC", {name: "transistor", description: "Backend for calculating graph shortest and most effictient path for Jira automation"}, "slack-proxy"]
    },
    {
        companyName: "Nordea",
        place: "Gdańsk, Gdynia",
        time: "Oct 2017 – Dec 2018",
        title: "Senior IT Developer",
        technologies: [SkillsList.SCALA, SkillsList.AKKA, SkillsList.CATS_EFFECT, SkillsList.K8S, SkillsList.OPENSHIFT, SkillsList.BAZEL, SkillsList.KAFKA, SkillsList.PSQL, SkillsList.REDIS, SkillsList.PROTOBUF],
        projects: [{name:"CRAFT", description: "Data catalog for data lake"}, {name:"governance-hub", description:"System of governance access for data lake"}]
    },
    {
        companyName: "Assertis",
        place: "Gdańsk, London, Remote",
        time: "Jul 2014 - Oct 2017",
        title: "Full Stack Developer, Team Leader, Scrum master",
        technologies: [SkillsList.SCALA, SkillsList.GO, SkillsList.PHP, SkillsList.TYPESCRIPT, SkillsList.NODEJS, SkillsList.ANGULARJS, SkillsList.REACT, SkillsList.MYSQL, SkillsList.MARIADB, SkillsList.ANSIBLE, SkillsList.MEMCACHED, SkillsList.AWS],
        projects: ["Caledonian Sleeper", "Railsmartr"]
    },
    {
        companyName: "On Track Retail",
        place: "London",
        time: "Apr 2015 - Sep 2015",
        title: "Scala Developer",
        technologies: [SkillsList.SCALA, SkillsList.SPRAY, SkillsList.SLICK, SkillsList.RABBITMQ],
        projects: ["payment-service", "admin-panel"]
    },
    {
        companyName: "Business Software Solutions",
        place: "Gdańsk, Sopot",
        time: "Mar 2013 - Jun 2014",
        title: "Developer",
        technologies: [SkillsList.SCALA, SkillsList.AKKA_ACTORS, SkillsList.SLICK, SkillsList.PLAY_FRAMEWORK, SkillsList.PHP, SkillsList.MYSQL, SkillsList.JAVA],
        projects: ["Amber Expo", "Finst", "Zelga"]
    },
    {
        companyName: "Wirtualna Polska",
        place: "Gdańsk",
        time: "Apr 2013 - Dec 2013",
        title: "PHP Developer",
        technologies: [SkillsList.PHP, SkillsList.JAVA],
        projects: ["rozrywka.wp.pl", "komiksy.wp.pl"]
    },
    {
        companyName: "Polcode",
        place: "Remote",
        time: "Sep 2012 – Feb 2013",
        title: "PHP Developer",
        technologies: [SkillsList.PHP, SkillsList.SELENIUM, SkillsList.MYSQL],
        projects: ["Evertalk", "Crossfit"]
    }
]

export const Experience: FC<{}> = () => {
    return (
        <>
            {projects.map(project => <Project {...project} key={project.companyName}/>)}
        </>
    )
}