export enum SkillsList {
    SCALA = "scala",
    SCALA3 = "scala3",
    CATS = "cats",
    CATS_EFFECT = "cats-effect",
    ZIO = "zio",
    MONIX = "monix",
    AKKA = "akka",
    AKKA_ACTORS = "akka-actors",
    AKKA_STREAMS = "akka-streams",
    ALPAKKA = "alpakka",
    AKKA_HTTP = "akka-http",
    HTTP4S = "http4s",
    TAPIR = "tapir",
    SCALA_JS = "scala-js",
    DOOBIE = "Doobie",
    QUILL = "quill",
    SLICK = "slick",
    KAFKA = "kafka",
    ELASTIC = "elastic",
    REDIS = "redis",
    PSQL = "postgresql",
    PROTOBUF = "protobuf",
    DOCKER = "Docker",
    K8S = "Kubernetes",
    TYPESCRIPT = "Typescript",
    REACT = "React",
    REACT_HOOKS = "Rect Hooks",
    JAVASCRIPT = "Javascript (ES5, ES6)",
    NODEJS = "nodejs",
    PLAY_FRAMEWORK = "Play Framework",
    KOAJS = "koajs",
    OPENSHIFT = "OpenShift",
    BAZEL = "bazel",
    MYSQL = "MySQL",
    MARIADB = "MariaDB",
    ANSIBLE = "Ansible",
    RABBITMQ = "rabbitMQ",
    SELENIUM = "Selenium",
    ANGULARJS = "Angularjs",
    ANGULAR = "Angular",
    PHP = "PHP",
    JAVA = "Java",
    SPRAY = "spray.io",
    GO = "go",
    MOBX = "mobx",
    RXJS = "Rxjs",
    MEMCACHED = "Memcached",
    AWS = "AWS",
    GRAPHQL = "graphql",
    FLINK = "Flink",
    PYTHON = "python",
}